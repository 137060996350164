<template>
<div>
  <div class="container ">
    <div class="flex flex-row items-center" style="margin-top: 77px; margin-bottom: 36px;">
      <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
      <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">ข่าวประชาสัมพันธ์</div>
    </div>

    <!-- 0 -->
    <div class="" v-if="checkPagination === null || checkPagination === 0">
      <div class="flex flex-wrap w-full mb-8">
        <div class="w-full sm:w-full lg:w-2/3 md:w-full">
          <cardNews
              :imageBackground="`${imageOne}`"
              title="ประกาศผลการพิจารณาโครงการกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม"
              content="ประกาศผลการพิจารณาโครงการ / กิจกรรมที่ได้รับการสนับสนุน จากกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม ประจำปีงบประมาณ พ.ศ. ๒๕๖๓ ครั้งที่ ๒ ประเภทคำขอที่มีวงเงินไม่เกิน / เกิน ๑๐ ล้าน"
              containerHeight="500"
              containerMaxHeight="500"
              classEdit="lg:mr-4 lg:mb-4"
              conditionImage="sm:h-1/3 md:h-1/2 lg:h-1/2"
          />
        </div>
        <div class="w-full sm:w-full lg:w-1/3 md:w-1/2 sm:w-1/2">
          <cardNews
              :imageBackground="`${imageTwo}`"
              title="คณะกรรมการบริหารกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจ และสังคม"
              content="ประชุมพิจารณาอนุมัติโครงการตามประกาศเปิดรับข้อเสนอ โครงการประจำปีงบประมาณ พ.ศ.2563..."
              containerHeight="500"
              containerMaxHeight="500"
              conditionImage="sm:h-1/2 md:h-1/2 lg:h-1/2"
          />
        </div>
        <div class="w-full sm:w-full lg:w-1/3 md:w-1/2 sm:w-1/2">
          <cardNews
              :imageBackground="`${imageTwo}`"
              title="คณะกรรมการบริหารกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจ และสังคม"
              content="ประชุมพิจารณาอนุมัติโครงการตามประกาศเปิดรับข้อเสนอ โครงการประจำปีงบประมาณ พ.ศ.2563..."
              containerHeight="500"
              containerMaxHeight="500"
              classEdit="lg:mr-4 lg:mb-4"
              conditionImage="sm:h-1/2 md:h-1/2 lg:h-1/2"
          />
        </div>
        <div class="w-full sm:w-full lg:w-1/3 md:w-1/2 sm:w-1/2">
          <cardNews
              :imageBackground="`${imageTwo}`"
              title="คณะกรรมการบริหารกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจ และสังคม"
              content="ประชุมพิจารณาอนุมัติโครงการตามประกาศเปิดรับข้อเสนอ โครงการประจำปีงบประมาณ พ.ศ.2563..."
              containerHeight="500"
              containerMaxHeight="500"
              classEdit="lg:mr-4 lg:mb-4"
              conditionImage="sm:h-1/2 md:h-1/2 lg:h-1/2"
          />
        </div>
        <div class="w-full sm:w-full lg:w-1/3 md:w-1/2 sm:w-1/2">
          <cardNews
              :imageBackground="`${imageTwo}`"
              title="คณะกรรมการบริหารกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจ และสังคม"
              content="ประชุมพิจารณาอนุมัติโครงการตามประกาศเปิดรับข้อเสนอ โครงการประจำปีงบประมาณ พ.ศ.2563..."
              containerHeight="500"
              containerMaxHeight="500"
              conditionImage="sm:h-1/2 md:h-1/2 lg:h-1/2"
          />
        </div>
      </div>
    </div>

    

    <ul class="flex items-center justify-center justify-items-center" style="margin-top: 33px; margin-bottom: 79px;">
      <li class="mx-1 px-3 py-2 rounded-tl-full rounded-bl-full" id="backgroundId0" @click="changeColorBackground(0)" style="color: #573C81; background-color: rgba(199, 179, 232, 0.3);">
        <div class="flex items-center font-bold">
          <span class="mx-1">1</span>
        </div>
      </li>
      <li class="mx-1 px-3 py-2 rounded-lg" id="backgroundId1" @click="changeColorBackground(1)" style="color: #573C81;  background-color: rgba(199, 179, 232, 0.3);">
        <div class="font-bold">2</div>
      </li>
      <li class="mx-1 px-3 py-2 rounded-tr-full rounded-br-full" id="backgroundId2" @click="changeColorBackground(2)" style="color: #573C81; background-color: rgba(199, 179, 232, 0.3);">
        <div class="flex items-center font-bold">
          <span class="mx-1">3</span>
        </div>
      </li>
    </ul>

  </div>
</div>
</template>

<script>
import cardNews from '@/components/news/cardNews.vue';
import imageOne from '@/assets/mog/card1.png';
import imageTwo from '@/assets/mog/card2.svg';
import imageThree from '@/assets/mog/icon.jpg';
export default {
  components: {
    cardNews,
  },
  mounted() {
    this.changeColorBackground(null);
  },
  methods: {
    changeColorBackground(id) {
      console.log(id)
      let colorId1 = document.getElementById("backgroundId0");
      let colorId2 = document.getElementById("backgroundId1");
      let colorId3 = document.getElementById("backgroundId2");
      
      if (id === 0) {
        this.checkPagination = 0;
        colorId1.style.backgroundColor = '#C7B3E8';
        colorId2.style.backgroundColor = 'rgba(199, 179, 232, 0.3)';
        colorId3.style.backgroundColor = 'rgba(199, 179, 232, 0.3)';
        
      } else if (id === 1) {
        this.checkPagination = 1;
        colorId1.style.backgroundColor = 'rgba(199, 179, 232, 0.3)';
        colorId2.style.backgroundColor = '#C7B3E8';
        colorId3.style.backgroundColor = 'rgba(199, 179, 232, 0.3)';
      } else if (id === 2) {
        this.checkPagination = 2;
        colorId1.style.backgroundColor = 'rgba(199, 179, 232, 0.3)';
        colorId2.style.backgroundColor = 'rgba(199, 179, 232, 0.3)';
        colorId3.style.backgroundColor = '#C7B3E8';
      } else if (id === null) {
        this.checkPagination = 0;
        colorId1.style.backgroundColor = '#C7B3E8';
        colorId2.style.backgroundColor = 'rgba(199, 179, 232, 0.3)';
        colorId3.style.backgroundColor = 'rgba(199, 179, 232, 0.3)';
      }
    },
  },
  data () {
    return {
      imageOne: imageOne,
      imageTwo: imageTwo,
      imageThree: imageThree,
      checkPagination: null,
    }
  }
}
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

/* a:hover {
 background-color: #C7B3E8;
} */

</style>
