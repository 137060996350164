<template>
<div class="">
  <div :class="`${classEdit} md:mr-2 lg:mr-2 mb-2 h-full max-h-full rounded overflow-hidden`" :style="`height: ${containerHeight}px; max-height: ${containerMaxHeight}px; border-width: 1px;`">
    <img :class="`object-cover w-full ${conditionImage}`" :src="`${imageBackground}`">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2" style="color: #000000;">{{title}}</div>
      <hr style="color: #FAB8B4; width: 50%; border-width: 2px; border: solid; margin-top: 20px; margin-bottom: 20px;" />
      <p class="text-sm" style="color: #707070;">
        {{content}}
      </p>

      <div class="text-sm mt-4 mb-4" style="color: #9C6DE8;">
        <a href="/news/1">
          อ่านต่อ <font-awesome-icon icon="long-arrow-alt-right" color="#9C6DE8" />
        </a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  // props: {
  //   imageBackground: String,
  //   title: String,
  //   content: String,
  //   containerHeight: String,
  //   containerMaxHeight: String,
  // },
  props: ['imageBackground', 'title', 'content', 'containerHeight', 'containerMaxHeight', 'classEdit', 'conditionImage'],
}
</script>