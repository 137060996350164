<template>
    <div class="flex items-center justify-center justify-items-center">
        <div class="container mb-8">
          <div class="flex flex-row items-center" style="margin-top: 74px; margin-bottom: 18px;">
            <div class="flex flex-row items-center w-1/2">
              <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
              <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">ประกาศเปิดรับข้อเสนอโครงการ</div>
            </div>

            <div class="text-right w-1/2" style="color: #9C6DE8;">
              <a href="/request" class="text-sm">
                ดูเพิ่มเติม <font-awesome-icon icon="long-arrow-alt-right" color="#9C6DE8" />
              </a>
            </div>
          </div>

          <div>
            <img src="@/assets/mog/1_TH-TH.jpg" class="w-full object-cover" style="width: 100%; height: 100%; max-height: 450px; margin-bottom: 80px;" />
          </div>
        </div>
    </div>
</template>

<style scoped>
.container {
  font-family: KanitFont;
}
</style>