<template>
  <div>
    <div class="container">
      <div class="w-full flex flex-wrap">

        <div class="md:border-r-4 lg:border-r-4 border-b-4 border-white content w-full lg:w-1/2 md:w-1/2 flex items-center justify-center">        
          <div style="position: relative; text-align: center; width: 100%; height: 100%;">
            <div class="flex items-center justify-center justify-items-center sm:text-lg md:text-2xl lg:text-3xl md:p-8 lg:p-20 p-20 truncate" :style="`background-image: url(${linkUrl}/image/inno.jpg); width: 100%; height: 100%; opacity: 0.15;`">
            </div>
            <div class="centered truncate">ความรู้ด้านเทคโนโลยี</div>
          </div>
        </div>

        <router-link to="/knowledge/video" class="md:border-r-4 lg:border-r-4 border-b-4 border-white w-full content1 md:w-1/4 lg:w-1/4 flex items-center justify-center">
          <div class="md:p-8 lg:p-20 p-20">
            <div class="mb-4" align="center">
              <img class="object-contain" src="@/assets/icon/youtube.svg">
            </div>
            <div class="text-md truncate">คลังวิดีโอมัลติมิเดีย</div>
          </div>
        </router-link>

        <router-link to="/knowledge/photo" class="border-b-4 border-white w-full content1 md:w-1/4 lg:w-1/4 flex items-center justify-center md:rounded-tr-4xl lg:rounded-tr-4x">
          <div class="md:p-8 lg:p-20 p-20">
            <div class="mb-4" align="center">
              <img class="object-contain" src="@/assets/icon/media.svg">
            </div>
            <div class="text-md truncate">คลังภาพอินโฟกราฟิก</div>
          </div>
        </router-link>

        <router-link to="/knowledge/data" class="md:border-r-4 lg:border-r-4 border-b-4 border-white w-full content1 md:w-1/4 lg:w-1/4 flex items-center justify-center">
          <div class="md:p-8 lg:p-20 p-20">
            <div class="mb-4" align="center">
              <img class="object-contain" src="@/assets/icon/data.svg">
            </div>
            <div class="text-md truncate">ข้อมูลวิชาการ</div>
          </div>
        </router-link>

        <router-link to="/knowledge/open" class="md:border-r-4 lg:border-r-4 border-b-4 border-white w-full content1 md:w-1/4 lg:w-1/4 flex items-center justify-center">
          <div class="md:p-8 lg:p-20 p-20">
            <div class="mb-4" align="center">
              <img class="object-contain" src="@/assets/icon/open.svg">
            </div>
            <div class="text-md truncate">Open Government Data</div>
          </div>
        </router-link>

        <router-link to="/knowledge/process" class="border-b-4 border-white w-full content1 lg:w-1/2 md:w-1/2 flex items-center justify-center md:rounded-br-4xl lg:rounded-br-4xl">
          <div class="md:p-8 lg:p-20 p-20">
            <div class="mb-4" align="center">
              <img class="object-contain" src="@/assets/icon/zero.svg">
            </div>
            <div class="text-md truncate">ศูนย์วิเคราะห์ข้อมูลดิจิทัล</div>
          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
export default {
  setup() {
    let linkUrl = ref(process.env.VUE_APP_URL_API);
    return {linkUrl};
  }
}
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.content {
  color: #FFFFFF;
  background-color: #7B55B5;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.content1 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  background-color: #7B55B5;
  color: white;
}

@media only screen and (min-width: 768px) {
  .centered {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
  }
  .content1 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .centered {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
  }
}

</style>
