<template>
  <div class="backgroundColor" style="padding-top: 80px; padding-bottom: 80px;">
    <div class="container">
      <div class="grid lg:grid-cols-2 md:grid-cols-1 sm:cols-1 gap-2">
        <div
          class="flex items-center justify-center justify-items-center text-white w-full rounded-tl-4xl"
          style="background-color: #573c81"
        >
          <div
            class="border-white content w-full h-full flex items-center justify-center"
          >
            <div
              style="
                position: relative;
                text-align: center;
                width: 100%;
                height: 100%;
              "
            >
              <div
                class="rounded-tl-4xl flex items-center justify-center justify-items-center  truncate"
                :style="`background-image: url(${linkUrl}/image/paper.png); width: 100%; height: 347px; opacity: 0.15;`"
              ></div>
              <div class="centered truncate">ปฏิทินกิจกรรม</div>
            </div>
          </div>
        </div>
        <div>
          <v-date-picker
            is-expanded
            locale="th"
            :attributes="attributes"
            style="font-family: KanitFont; height: 100%;"
          />
        </div>
      </div>

      <div class="flex flex-row items-center" style="margin-top: 36px; margin-bottom: 36px;">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            กิจกรรม
          </div>
        </div>

        <div class="text-right w-1/2" style="color: #9c6de8">
          <a href="/activity" class="text-sm">
            ดูเพิ่มเติม
            <font-awesome-icon icon="long-arrow-alt-right" color="#9C6DE8" />
          </a>
        </div>
      </div>

      <div
        class="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4"
      >
        <!--Card 1-->
        <div
          class="rounded overflow-hidden"
          v-for="(item, index) in 4"
          :key="index"
          style="width: 100%; height: 400px; border-width: 1px; border-radius: 10px;"
        >
          <img class="object-cover w-full h-1/2" src="@/assets/mog/cover.jpg" />
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2" style="color: #fa4238">
              11 พฤษภาคม 2564
            </div>
            <p class="text-sm" style="color: #242424">
              สดช. ร่วมกับ ทส. ประชุมหารือโครงการศูนย์ ข้อมูลสิ่งแวดล้อมแห่งชาติ
              (National Environmental Open Data)
            </p>

            <div class="text-sm mt-4 mb-4" style="color: #9c6de8">
              <a href="/activity/1">
                อ่านต่อ
                <font-awesome-icon
                  icon="long-arrow-alt-right"
                  color="#9C6DE8"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkUrl: process.env.VUE_APP_URL_API,
      attributes: [
        {
          key: "today",
          highlight: "purple",
          dates: new Date(),
        },
      ],
    };
  },
};
</script>

<style scoped>
.backgroundColor {
  background-color: rgba(199, 179, 232, 0.1);
}

.container {
  font-family: KanitFont;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 768px) {
  .centered {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
  }
}

@media only screen and (min-width: 1024px) {
  .centered {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
  }
}
</style>