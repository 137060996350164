<template>
  <div class="h-full">
    <carousel :items-to-show="1">
      <slide v-for="(item, index) in items" :key="index">
        <!-- {{ slide }} -->
        <!--        <img src="@/assets/mog/Component6.png" class="w-full h-full object-cover" />-->
        <img :src="`${item.linkImg}`" class="w-full object-cover" style="height: 100%; max-height: 600px;" />
      </slide>
      <template #addons>
        <pagination class="bottom-right" />
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import imageOne from "@/assets/mog/Component6.png";
import imageTwo from "@/assets/mog/1_TH-TH.jpg";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      items: [
        {
          linkImg: imageOne,
        },
        {
          linkImg: imageTwo,
        },
      ],
    };
  },
};
</script>

<style>
.carousel__pagination-button {
  width: 50px;
  background-color: rgba(255, 255, 255, 0.3);
}

.carousel__pagination-button--active {
  background-color: #FFFFFF;
}

.carousel__pagination-button--active:focus {
  /* background-color: #FFFFFF; */
  outline: none;
}

.bottom-right {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
