<template>
    <div>
        <carouselComponent/>
        <announceComponent/>
        <knowledgeComponent/>
        <newsComponent/>
        <activityComponent/>
    </div>
</template>

<script>
import carouselComponent from '@/components/carousel/carouselComponent.vue';
import announceComponent from '@/components/announce/announceComponent.vue';
import knowledgeComponent from '@/components/knowledge/knowledgeComponent.vue';
import newsComponent from '@/components/news/newsComponent.vue';
import activityComponent from '@/components/activity/activityComponent.vue';
export default {
    components: {
        carouselComponent,
        announceComponent,
        knowledgeComponent,
        activityComponent,
        newsComponent,
    }
}
</script>